import './MainPage.css'
import React from 'react'

import MapComponent from './MapComponent';
import RightDetailCard from './RigthDetailCard';


function MainPage() {

  const [markers, setMarkers] = React.useState([]);

  return (
    <>
      <div className="main-header">
        <img src={process.env.PUBLIC_URL + '/img/logo.png'} />
        <span>j 들의 여행 루트 jㅣ도</span>
      </div>
      <div className='main-back'>
        <div className='main-left-div'>
          <div className='map'>
            <MapComponent markers={markers} setMarkers = {setMarkers} />
          </div>
        </div>
        <div className='main-right-div'>
          <div className='map-info'>
            <div>
              <RightDetailCard markers={markers} setMarkers = {setMarkers} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



export default MainPage;


