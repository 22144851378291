import React, { useState } from 'react'
import { GoogleMap, Marker, useLoadScript, useJsApiLoader, InfoWindow, Polyline } from '@react-google-maps/api';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import MapStyles from './MapStyle';
import "@reach/combobox/styles.css";
// import { formatRelative } from "date-fns";




const MapComponent = ( {markers, setMarkers} ) => {

  const libraries = ["places"];
  const mapContainerStyle = {
    height: "100%",
    width: "100%",
  };
  const options = {
    // styles: MapStyles,
    disableDefaultUI: true,
    zoomControl: true,
  };

  const [center, setCenter] = React.useState( { 
    lat: 37.4887,
    lng: 126.9519,
  } )

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyA29oU4gAB_sYbGgdSTfQ8o0qmbwFuzg8M",
    libraries,
  });
  // const [markers, setMarkers] = React.useState([]);
  const [selected, setSelected] = React.useState(null);

  const onMapClick = React.useCallback((e) => {
    setMarkers((current) => [
      ...current,
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        time: new Date(),
        showRoute:'',
        showMove:'',
        contentRoute:{ name:'', time:'', price:'', memo:'' },
        contentMove:{ name:'', time:'', price:'', memo:'' },
      },
    ]);
  }, []);

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(15);
  }, []);

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <>
      <Locate panTo={panTo} />
      <Search panTo={panTo} setMarkers={setMarkers} />

      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={9}
        center={center}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
      >
        {
          markers.map((marker, index) => {
            
            // 마커 숫자 표시 위한 변수지정
            let numMarker = index+1 
            if( index>15 ){
              numMarker = 16
            }

            // polyline을 위한 어레이 만들기
            let polylineCoods = []
            if ( index >= 1 ){
              polylineCoods = [
              {lat: markers[index-1].lat, lng: markers[index-1].lng},
              {lat: marker.lat, lng: marker.lng} ]
            }
      
            return(
              <>
                <Marker
                  key={`${marker.lat}-${marker.lng}`}
                  position={{ lat: marker.lat, lng: marker.lng }}
                  onClick={() => {
                    setSelected(marker);
                    console.log(marker);
                  }}
                  icon={{
                    url: `/img/${numMarker}.svg`,
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(40, 80),
                    scaledSize: new window.google.maps.Size(80, 80),
                  }}
                />
                <Polyline
                  path={polylineCoods}
                  options={{
                    strokeColor:"#191BA9",
                    strokeOpacity:0.8,
                    strokeWeight:1.5,
                  }}
                />
              </>
            )
          })
        }

        { selected ? (
          <InfoWindow
            position={{ lat: selected.lat, lng: selected.lng }}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <div className='selected-div'>
              <h3>
                {selected.contentRoute.name}
              </h3>
              <div> 시간 : {selected.contentRoute.time} </div>
              <div> 가격 : {selected.contentRoute.price} </div>
              <div> 메모 : {selected.contentRoute.memo} </div>
              <hr/>
              <div> 다음 이동수단 : {selected.contentMove.name} </div>
              <div> 시간 : {selected.contentMove.time} </div>
              <div> 가격 : {selected.contentMove.price} </div>
              <div> 메모 : {selected.contentMove.memo} </div>
            </div>
          </InfoWindow>
        ) : null}

      </GoogleMap>
    </>
  );
}

function Locate({ panTo }) {
  return (
    <button
      className="locate"
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => null
        );
      }}
    >
      <img src="/img/direction.png" alt="compass" />
    </button>
  );
}

function Search({ panTo, setMarkers }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 37.4887, lng: () => 126.9519 },  // 37.48872716129306 , 126.95195128416191   // 43.6532, -79.3832
      radius: 100 * 1000,
    },
  });

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest

  const handleInput = (e) => {
    setValue(e.target.value);
    // console.log(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    // console.log(address);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
      setMarkers((current) => [
        ...current,
        {
          lat: lat,
          lng: lng,
          time: new Date(),
          showRoute:'',
          showMove:'',
          contentRoute:{ name:'', time:'', price:'', memo:'' },
          contentMove:{ name:'', time:'', price:'', memo:'' },
        },
      ]);
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };

  return (
    <div className="search">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Search location"
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}


export default MapComponent;



