import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faChevronDown, faChevronUp, faArrowRight, faArrowUp, faArrowDown, faTrash  } from "@fortawesome/free-solid-svg-icons";
import React from 'react'



const RightDetailCard = ({markers, setMarkers} )=>{

  return(
    <>
      {
        markers.length === 0 ?
        <>
          <div className="main-right_empty">
              <p>선택한 여행지가 없습니다. 🛫</p>
              <p>소중한 추억이 될 여행지를 지도에서 선택하세요</p>
          </div>
        </>
        :
        <>
          <div className="detail-route-div-header">
            <span>루트 플랜 🧳</span>
          </div>
          {
            markers.map((data, index)=>{
              return(
                <div className='detail-route-div' key={data.lat-data.lng}>
                  <div className='detail-route-div-step'>
                    <div className='detail-route-left'>
                      <div className='route-num'>{index+1}</div>
                    </div>
                    <div className='detail-route-right'>
                      <div className='travel-title'>
                        {
                          data.contentRoute.name != ''?
                          <span>{data.contentRoute.name}</span>
                          :
                          <span>{index+1}번째 여행지</span>
                        }
                      </div>
                      <div className='travel-detail-btn-back'>
                        { // 위로 한칸
                          index+1 >= 2?                    
                          <div className='travel-detail-btn' onClick={()=>{
                              let newMarker = [...markers];
                              let tmp = newMarker[index];
                              newMarker[index] = newMarker[index-1] 
                              newMarker[index-1] = tmp
                              setMarkers(newMarker);
                              // console.log(newMarker);
                            }}>
                            <FontAwesomeIcon icon={faArrowUp} />
                          </div>
                          : null
                        }
                        { // 아래로 한칸
                          index+1 < markers.length?                    
                          <div className='travel-detail-btn' onClick={()=>{
                              let newMarker = [...markers];
                              let tmp = newMarker[index];
                              newMarker[index] = newMarker[index+1] 
                              newMarker[index+1] = tmp
                              setMarkers(newMarker);
                              // console.log(newMarker);
                            }}>
                            <FontAwesomeIcon icon={faArrowDown} />
                          </div>
                          : null
                        }
                        <div className='travel-detail-btn' onClick={()=>{
                          let newMarker = [...markers];
                          newMarker.splice(index,1);
                          setMarkers(newMarker);
                          // console.log(newMarker);
                        }}>
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                        <div className='travel-detail-btn' onClick={()=>{
                            let newMarker = [...markers];
                            if( newMarker[index].showRoute=='' ){
                              newMarker[index].showRoute = 'show-route-detail'
                            } else{
                              newMarker[index].showRoute = ''
                            }
                            // console.log(newMarker[index].showRoute);
                            setMarkers(newMarker);
                          }}>
                          <FontAwesomeIcon icon={ data.showRoute===''? faChevronDown : faChevronUp } />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`detail-route-hidden-div ${data.showRoute}`}>
                    <div className='detail-route-hidden-left'>
                      <div className='detail-route-hidden-num-line'>
                        <div></div>
                      </div>
                    </div>
                    <div className='detail-route-hidden-right'>
                      <div>
                        <span className='detail-route-hidden-sub-title'>이름</span>
                        <input className='memo-input' value={data.contentRoute.name} onChange={(e)=>{
                            let newMarker = [...markers];
                            newMarker[index].contentRoute.name = e.target.value;
                            setMarkers(newMarker);
                            // console.log(newMarker);
                          }} />
                      </div>
                      <div>
                        <span className='detail-route-hidden-sub-title'>시간</span>
                        <input className='memo-input' value={data.contentRoute.time} onChange={(e)=>{
                            let newMarker = [...markers];
                            newMarker[index].contentRoute.time = e.target.value;
                            setMarkers(newMarker);
                          }} />
                      </div>
                      <div>
                        <span className='detail-route-hidden-sub-title'>가격</span>
                        <input className='memo-input' value={data.contentRoute.price} onChange={(e)=>{
                            let newMarker = [...markers];
                            newMarker[index].contentRoute.price = e.target.value;
                            setMarkers(newMarker);
                          }} />
                      </div>
                      <div>
                        <div>
                          <span className='detail-route-hidden-sub-title memo-sub-title'>메모</span>
                        </div>
                        <textarea className='memo-textarea' value={data.contentRoute.memo} onChange={(e)=>{
                            let newMarker = [...markers];
                            newMarker[index].contentRoute.memo = e.target.value;
                            setMarkers(newMarker);
                          }}/>
                      </div>
                    </div>
                  </div>

                  <div className='detail-route-div-step'>
                    <div className='detail-route-left'>
                      <div className='route-num-line'>
                        <div></div>
                      </div>
                    </div>
                    <div className='detail-route-right'>
                      <div className="detail-route-move-summary">
                      {
                        data.contentRoute.time != ''?
                          <span>{data.contentRoute.time}</span>
                        :
                        null
                      }
                                            {
                        data.contentRoute.price != ''?
                          <>
                            <span> / </span>
                            <span>{data.contentRoute.price}</span>
                          </>
                        :
                        null
                      }
                                            {
                        data.contentRoute.memo != ''?
                        <>
                          <span> / </span>
                          <span>{data.contentRoute.memo}</span>
                        </>
                        :
                        null
                      }

                      </div>
                    </div>
                  </div>

                  <div className='detail-route-div-step move-icon-div'>
                    <div className='detail-route-left'>
                      <img src='/img/move.png'/>
                    </div>
                    <div className='detail-route-right move-title'>
                      <div>
                        {
                          data.contentMove.name != ''?
                          <span>{data.contentMove.name}</span>
                          :
                          <span>이동</span>
                        }
                      </div>
                      <div className='move-detail-btn' onClick={()=>{
                            let newMarker = [...markers];
                            if( newMarker[index].showMove=='' ){
                              newMarker[index].showMove = 'show-route-detail'
                            } else{
                              newMarker[index].showMove = ''
                            }
                            // console.log(newMarker[index].showMove);
                            setMarkers(newMarker);
                          }}>
                          <FontAwesomeIcon icon={data.showMove===''? faChevronDown : faChevronUp} />
                      </div>
                    </div>
                  </div>

                  {/* 히든창 */}
                  <div className={`detail-route-hidden-div ${data.showMove}`}>
                    <div className='detail-route-hidden-left'>
                      <div className='detail-route-hidden-num-line'>
                        <div></div>
                      </div>
                    </div>
                    <div className='detail-route-hidden-right'>
                      <div>
                        <span className='detail-route-hidden-move-title'>수단</span>
                        <input className='memo-input' value={data.contentMove.name} onChange={(e)=>{
                            let newMarker = [...markers];
                            newMarker[index].contentMove.name = e.target.value;
                            setMarkers(newMarker);
                          }}/>
                      </div>
                      <div>
                        <span className='detail-route-hidden-move-title'>시간</span>
                        <input className='memo-input' value={data.contentMove.time} onChange={(e)=>{
                            let newMarker = [...markers];
                            newMarker[index].contentMove.time = e.target.value;
                            setMarkers(newMarker);
                          }}/>
                      </div>
                      <div>
                        <span className='detail-route-hidden-move-title'>가격</span>
                        <input className='memo-input' value={data.contentMove.price} onChange={(e)=>{
                            let newMarker = [...markers];
                            newMarker[index].contentMove.price = e.target.value;
                            setMarkers(newMarker);
                          }}/>
                      </div>
                      <div>
                        <div>
                          <span className='detail-route-hidden-move-title memo-sub-title'>메모</span>
                        </div>
                        <textarea className='memo-textarea' value={data.contentMove.memo} onChange={(e)=>{
                            let newMarker = [...markers];
                            newMarker[index].contentMove.memo = e.target.value;
                            setMarkers(newMarker);
                          }}/>
                      </div>
                    </div>
                  </div>

                  <div className='detail-route-div-step'>
                    <div className='detail-route-left'>
                      <div className='route-num-line'>
                        <div></div>
                      </div>
                      <div className='route-num-line-vertical'>
                        <div></div>
                      </div>
                    </div>
                    <div className='detail-route-right'>
                      <div className="detail-route-move-summary">
                      {
                        data.contentMove.time != ''?
                          <span>{data.contentMove.time}</span>
                        :
                        null
                      }
                      {
                        data.contentMove.price != ''?
                          <>
                            <span> / </span>
                            <span>{data.contentMove.price}</span>
                          </>
                        :
                        null
                      }
                      {
                        data.contentMove.memo != ''?
                          <>
                            <span> / </span>
                            <span>{data.contentMove.memo}</span>
                          </>
                        :
                        null
                      }
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </>
      }
    </>
  )
}

export default RightDetailCard;





